"use client";

import React, { useState, useEffect } from 'react';

interface Review {
  id: number;
  name: string;
  content: string;
  date: string;
}

const reviews: Review[] = [
  {
    id: 1,
    name: "Sue B",
    content: "DB Property Services did an amazing job on our home renovation. Our bathroom had to be completely gutted and rebuilt, and they did a fantastic job. Highly recommended!",
    date: "15 May 2024",
  },
  {
    id: 2,
    name: "Jane S",
    content: "Professional, punctual, and high-quality work. Couldn't be happier with the results.",
    date: "3 April 2023",
  },
  {
    id: 3,
    name: "Maria B",
    content: "Great attention to detail and excellent communication throughout the project.",
    date: "22 March 2023",
  },
];

export default function ReviewSlider() {
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const bodyFont = "font-sans font-semibold";

  return (
    <section className="py-16 relative">
      <div className="absolute top-0 left-0 right-0 h-1/2 bg-gray-100 rounded-b-3xl"></div>
      <div className="container mx-auto px-4 relative z-10">
        <p className="text-l font-semibold text-green-600 mb-2 text-center uppercase">Testimonials</p>
        <h2 className="text-4xl font-bold mb-8 text-center">What Our Clients Say</h2>
        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="mb-4">
              <h3 className="text-xl font-semibold">{reviews[currentReview].name}</h3>
              <p className="text-sm text-gray-500">{reviews[currentReview].date}</p>
            </div>
            <p className={`${bodyFont} text-gray-600 italic`}>&ldquo;{reviews[currentReview].content}&rdquo;</p>
          </div>
          <div className="flex justify-center mt-4">
            {reviews.map((_, index) => (
              <button
                aria-label={`Select review ${index + 1}`}
                key={index}
                className={`w-5 h-5 rounded-full mx-5 ${
                  index === currentReview ? 'bg-green-500' : 'bg-gray-300'
                }`}
                onClick={() => setCurrentReview(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
