import React from 'react';

interface MessageProps {
  message: string;
  type: 'success' | 'error';
  onClose: () => void;
}

export default function Message({ message, type, onClose }: MessageProps) {
  const bgColor = type === 'success' ? 'bg-green-100' : 'bg-red-100';
  const textColor = type === 'success' ? 'text-green-800' : 'text-red-800';
  const iconColor = type === 'success' ? 'text-green-400' : 'text-red-400';

  return (
    <div className={`fixed top-0 left-0 right-0 z-50 ${bgColor} border-t-4 border-b-4 border-${type === 'success' ? 'green' : 'red'}-500 px-4 py-3 shadow-md`} role="alert">
      <div className="mx-auto max-w-7xl container lg:px-8 flex">
        <div className="py-1">
          <svg className={`fill-current h-6 w-6 ${iconColor} mr-4`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
          </svg>
        </div>
        <div>
          <p className={`font-bold ${textColor}`}>{type === 'success' ? 'Success' : 'Error'}</p>
          <p className={`text-sm ${textColor}`}>{message}</p>
        </div>
        <div className="ml-auto">
          <button aria-label="Close message" onClick={onClose} className={`${textColor} font-semibold`}>
            <svg className="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
